/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-11-2020.
 */
import React from 'react'

import './terms.scss'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

const privacyTemplate = (props) => {
  //const strapiPrivacies = props.data.strapiPrivacies

  return (
    <Layout>
      <SEO title="Studies&me" />

      <div className="container terms mt-6 ">
        <div className="row" style={{}}>
          <div className="col-10 col-md-8 mx-auto mt-6">
            {/*<ReactMarkdown children={strapiPrivacies.bodyText}></ReactMarkdown>*/}
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12" style={{ height: '5rem' }}></div>
        </div>
      </div>
    </Layout>
  )
}

/*export const query = graphql`
  query BlogPostQuery($privacyUrl: String) {
    strapiPrivacies(url: { eq: $privacyUrl }) {
      bodyText
      title
      url
    }
  }
`*/

export default privacyTemplate
